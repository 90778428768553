import {inject, injectable} from "inversify";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Component} from "../interface/component";

@injectable()
export class ButtonComponent implements Component
{
    protected $root:JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element:HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    private bindUI() {
        this.eventEmitter.addListener(EVENTS.FORM_SUBMITTED, this.disableButton);
        this.eventEmitter.addListener(EVENTS.FORM_LOADED, this.enableButton);
        this.$root.on({
            'disable': this.disableButton,
            'enable': this.enableButton
        });
    }

    /**
     * Disable the button
     */
    disableButton = () => {
        this.$root.attr('disabled', 'disabled');
        this.$root.addClass('disabled');
        if (this.$root.data('loadinglabel')) {
            this.$root.data('loaded-label', this.$root.text());
            this.$root.text(this.$root.data('loadinglabel'));
        }
    };

    /**
     * Enable the button
     */
    enableButton = () => {
        this.$root.removeAttr('disabled');
        this.$root.removeClass('disabled');
        if (this.$root.data('loaded-label')) {
            this.$root.data('loadinglabel', this.$root.text());
            this.$root.text(this.$root.data('loaded-label'));
        }
    };
}