const EVENTS = {

    RELOAD_COMPONENTS: 'components.reload',

    FLASH_MESSAGE: 'flash.message',
    FLASH_CLEAR: 'flash.clear',

    PAGE_LOADING: 'page.loading',
    PAGE_LOADED: 'page.loaded',
    FORM_LOADING: 'form.loading',
    FORM_ERROR: 'form.error',
    FORM_RESET: 'form.reset',
    FORM_LOADED: 'form.loaded',
    FORM_PRESUBMIT: 'form.presubmit',
    FORM_SUBMITTED: 'form.submitted',

    STRUCTURE_UPDATED: 'structure.updated',
    CONTENT_REMOVED: 'content.removed',
    SORT_START: 'sort.start',
    SORT_STOP: 'sort.stop',
    COMMISSIONS_UPDATE: 'commissions,update',
    COMMISSIONS_RELOADED: 'commissions.reloaded',

    UPLOAD_STARTED: 'upload.started',
    UPLOAD_PROGRSS: 'upload.progress',
    UPLOAD_FINISHED: 'upload.finished',
    UPLOAD_COMPLETED: 'upload.completed',
    UPLOAD_FAILED: 'upload.failed',

    INVOICE_UPDATE: 'invoice.update',
    INVOICE_UPDATED: 'invoice.updated',

    INVOICE_PAY: 'invoice.pay',
    INVOICE_PAID: 'invoice.paid',

    CUSTOMER_SELECTED: 'customer.selected',
};

export default EVENTS;