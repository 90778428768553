import * as $ from 'jquery';
import * as Awesomplete from "awesomplete";
import {inject, injectable} from "inversify";
import * as _ from "underscore";
import {Routing} from "../modules/router";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";

@injectable()
export class SelectCustomerComponent {

    protected lookupEndpoint: string;
    protected searching: boolean;
    protected $root: JQuery;
    protected $input: JQuery;
    protected $list: JQuery;
    protected $suggestions: JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(elem: HTMLElement)
    {
        this.$root = $(elem);
        this.$input = this.$root.find('.js-select-customer__input');
        this.$list = this.$root.find('.js-select-customer__list');
        this.lookupEndpoint = this.$root.data('lookup_endpoint');
        if (this.lookupEndpoint) {
            this.$suggestions = $(document.createElement('ul'));
            this.$suggestions.addClass('c-select-customer__options');
            this.$input.after(this.$suggestions);
            this.bindUI();
        }
    }

    bindUI = () => {
        this.eventEmitter.addListener(EVENTS.CUSTOMER_SELECTED, this.addCustomer);
        this.$list.find('.js-select-customer__remove').on({ click: this.removeCustomer });
            this.$input.on({
            blur: _.debounce(this.hideSuggestions, 500),
            focus: _.debounce(this.handleFocus, 300),
            keydown: _.debounce(this.handleSearch, 300)
        });
    };

    addCustomer = (label:string, id:string) => {
        let existing = false;
        this.$list.find('li').each((key:any, item:any) => {
            if (parseInt(<string>$(item).find('input').val()) === parseInt(id)) {
                existing = true;
            }
        });
        if (!existing) {
            let prototype = this.$list.data('prototype');
            let counter = this.$list.data('widget-counter') || this.$list.children().length;
            let $newWidget = $(prototype.replace(/__name__/g, counter));
            $newWidget.find('input').val(id);
            $newWidget.find('.js-select-customer__item').text(label);
            this.$list.data('widget-counter', ++counter);
            $newWidget.appendTo(this.$list);
            $newWidget.find('.js-select-customer__remove').on({ click: this.removeCustomer });
        }
    };

    removeCustomer = (ev:any) => {
        let $target = $(ev.currentTarget);
        $target.closest('li').remove();
    };

    selectSuggestion = (ev:any) => {
        this.$input.val(null);
        this.clearSuggestions();
        let selected = ev.currentTarget;
        this.eventEmitter.emit(EVENTS.CUSTOMER_SELECTED, $(selected).text(), $(selected).data('id'));
    };

    handleSuggestions = (response:any) => {
        this.clearSuggestions();
        this.searching = false;
        response.results.forEach((result:any) => {
            this.$suggestions.append('<li data-id="' + result.id + '">' + result.label + '</li>')
        });
        this.showSuggestions();
        this.$suggestions.find('li').on({ click: this.selectSuggestion })
    };

    handleFocus = () => {
        if (this.$suggestions.find('li').length > 0) {
            this.showSuggestions();
        }
    };

    showSuggestions = () => this.$suggestions.show();
    hideSuggestions = () => this.$suggestions.hide();
    clearSuggestions = () => this.$suggestions.find('li').remove();

    handleSearch = () => {
        let keyword = this.$input.val();
        if (!this.searching && (<string>keyword).length >= 3) {
            this.searching = true;
            this.clearSuggestions();
            this.showSuggestions();
            this.$suggestions.append('<li class="c-select-customer__loading">Searching....</li>');
            $
                .ajax(Routing.generate(this.lookupEndpoint, { search: { keyword: keyword } }))
                .done(this.handleSuggestions)
        }
    };
}