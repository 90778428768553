import {ComponentService} from "./service/component";
import container from "./container/config";
import SERVICE_IDENTIFIER from "./container/identifiers";
import {Application} from "./application/application";
import {ControlComponent} from "./components/control-component";
import {NavigationMobileComponent} from "./components/navigation-mobile-component";
import {ModalComponent} from "./components/modal-component";
import 'foundation-sites';
import 'ckeditor';
import 'jqueryui';
import 'jquery.cookie';
import {TAG} from "./container/tags";
import {SelectComponent} from "./components/select-component";

const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

require('../css/app.scss');
require("@babel/polyfill");

(($) => $(() => {
    $(document).foundation();
    let components = container.get<ComponentService>(SERVICE_IDENTIFIER.COMPONENTS);

    components.addComponent('.js-button', TAG.BUTTON_COMPONENT);
    components.addComponent('.js-control', ControlComponent);
    components.addComponent('.js-mobile-nav', NavigationMobileComponent);
    components.addComponent('.js-modal', ModalComponent);
    components.addComponent('.js-form', TAG.FORM_COMPONENT);
    components.addComponent('.js-flash', TAG.FLASH_COMPONENT);
    components.addComponent('.js-body', TAG.BODY_COMPONENT);

    components.addComponent('.js-table', TAG.TABLE_COMPONENT);
    components.addComponent('.js-location-form', TAG.LOOKUP_COMPONENT);
    components.addComponent('.js-commission-form', TAG.COMMISSION_FORM);
    components.addComponent('.js-commission-history', TAG.COMMISSION_HISTORY);

    components.addComponent('.js-invoice-form', TAG.INVOICE_FORM    );
    components.addComponent('.js-invoice-history', TAG.INVOICE_HISTORY);
    components.addComponent('.js-invoice-table', TAG.INVOICE_TABLE);
    components.addComponent('.js-invoice-update', TAG.INVOICE_UPDATE);

    components.addComponent('.js-selectric', SelectComponent);
    components.addComponent('.js-select-customer', TAG.CUSTOMER_SELECT_COMPONENT);

    // components.addComponent('.js-upload', TAG.UPLOAD_COMPONENT);
    components.addComponent('.js-upload-form', TAG.UPLOAD_FORM_COMPONENT);
    components.addComponent('.js-location-tool', TAG.LOCATION_TOOL_COMPONENT);
    components.addComponent('.js-progress', TAG.UPLOAD_PROGRESS);
    components.addComponent('.js-upload-button', TAG.UPLOAD_BUTTON_COMPONENT);

    container.get<Application>(SERVICE_IDENTIFIER.APPLICATION).init();
}))(jQuery);