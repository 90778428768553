import {inject, injectable} from "inversify";
import {Component} from "../interface/component";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Routing} from "../modules/router";
import {AbstractFormComponent} from "./abstract-form-component";

@injectable()
export class InvoiceUpdateComponent extends AbstractFormComponent {

    protected $root:JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element: HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    protected bindUI = () => {
        this.eventEmitter.addListener(EVENTS.INVOICE_UPDATE, this.handleStartUpdate);
        this.eventEmitter.addListener(EVENTS.FORM_SUBMITTED, this.lockForm);
        this.eventEmitter.addListener(EVENTS.FORM_RESET, this.resetForm);
        this.$root.find('form').on({ submit: this.handleSubmit })
    };

    protected lockForm = () => {
        this.$root.find(':input').prop('disabled', 'disabled');
    };

    protected resetForm = () => {
        console.log('should reset?', this.$root.find(':input'));
        this.$root.find(':input').removeAttr('disabled');
        this.$root.foundation('close');
    };

    protected handleSubmit = (event:any) => {
        event.preventDefault();
        let uuid = this.$root.data('uuid');
        this.eventEmitter.emit(EVENTS.FORM_SUBMITTED);
        $
            .ajax({
                type: 'POST',
                url: Routing.generate('invoice_update', { id: uuid }),
                contentType: false,
                processData: false,
                data: this.buildFormData()
            })
            .done((response) => {
                this.eventEmitter.emit(EVENTS.INVOICE_UPDATED, this.$root.data('item'), response.invoice.status, response.invoice.status_output, response.invoice.sent);
                this.eventEmitter.emit(EVENTS.FORM_RESET);
            })
            .fail(this.handleFormError)
            .always(() => this.eventEmitter.emit(EVENTS.FORM_LOADED))
        ;
    };

    protected handleStartUpdate = (target:any) => {
        // event.preventDefault();
        this.$root.find(':input').removeProp('disabled');
        this.$root.find('#update_invoice_status').val($(target).data('status'));
        if ($(target).data('sent')) {
            this.$root.find('#update_invoice_sent').prop('checked', 'checked');
        } else {
            this.$root.find('#update_invoice_sent').removeProp('checked');
        }
        this.$root.data('item', $(target).closest('tr'));
        this.$root.data('uuid', $(target).data('uuid'));
        this.$root.foundation('open');
    };
}