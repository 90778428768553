export class ControlComponent
{
    protected window: Window;
    protected $root:JQuery;

    constructor(element:HTMLElement) {
        this.window  = <any>window;
        this.$root = $(element);
        this.bindUI();
    }

    private bindUI() {
        if (this.$root.hasClass('js-control--check')) {
            this.$root.on({ click: this.checkClick });
        }
    }

    checkClick = (ev:MouseEvent) => {
        ev.preventDefault();
        let message = this.$root.data('message') ? this.$root.data('message') : 'Do you want to take this action?';
        if (confirm(message)) {
            this.window.location.href = this.$root.attr('href');
        }
    };
}