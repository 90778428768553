import {inject, injectable} from "inversify";
import {Component} from "../interface/component";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";

@injectable()
export class PageComponent implements Component {

    private $root:JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element: HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    protected bindUI = () => {
        this.eventEmitter.addListener('actions.loading', this.handleLoading);
        this.eventEmitter.addListener('actions.loaded', this.handleLoaded);
    };

    protected handleLoading = () => {
        this.$root.addClass('l-page--disabled');
    };

    protected handleLoaded = () => {
        this.$root.removeClass('l-page--disabled');
    };

}