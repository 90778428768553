import {inject, injectable} from "inversify";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Component} from "../interface/component";
import {Routing} from "../modules/router";

@injectable()
export class TableComponent implements Component
{
    protected $root:JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element:HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    private bindUI() {
        this.eventEmitter.addListener(EVENTS.UPLOAD_STARTED, this.disableTable);
        this.eventEmitter.addListener(EVENTS.UPLOAD_FINISHED, this.enableTable);
        if (this.$root.data('endpoint')) {
            this.eventEmitter.addListener(EVENTS.UPLOAD_COMPLETED, this.reloadData)
        }
    }

    private reloadData = () => {
        $
            .ajax(
                { url: Routing.generate(this.$root.data('endpoint')) }
            )
            .done((response) => {
                this.$root.html(response);
                this.eventEmitter.emit(EVENTS.RELOAD_COMPONENTS);
                this.eventEmitter.emit(EVENTS.UPLOAD_COMPLETED);
            })
            .fail(() => this.eventEmitter.emit(EVENTS.UPLOAD_FAILED))
            .always(() => {
                console.log('then this');
                this.eventEmitter.emit(EVENTS.UPLOAD_FINISHED);
            })
    };

    /**
     * Disable the button
     */
    disableTable = () => {
        this.$root.addClass('c-table--disabled');
    };

    /**
     * Enable the button
     */
    enableTable = () => {
        this.$root.removeClass('c-table--disabled');
    };
}