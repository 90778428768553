import {inject, injectable} from "inversify";
import {Component} from "../interface/component";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Routing} from "../modules/router";
import {AbstractFormComponent} from "./abstract-form-component";

@injectable()
export class InvoiceFormComponent extends AbstractFormComponent {

    protected $root:JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element: HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    protected bindUI = () => {
        this.$root.on('submit', this.handleSubmit);
        this.eventEmitter.addListener(EVENTS.UPLOAD_COMPLETED, this.handleFormSuccess);
        this.eventEmitter.addListener(EVENTS.FORM_SUBMITTED, this.handleSendingStart);
        this.eventEmitter.addListener(EVENTS.FORM_LOADED, this.handleSendingFinish);
    };

    protected handleSubmit = (event:any) => {
        event.preventDefault();
        this.eventEmitter.emit(EVENTS.FLASH_CLEAR);
        this.eventEmitter.emit(EVENTS.FORM_SUBMITTED);
        this.startUpload().then((uploadId) => this.handleUpload(uploadId))

    };

    protected handleUpload = (uploadId:any) => {
        this.eventEmitter.emit(EVENTS.UPLOAD_STARTED, uploadId);
        $
            .ajax({
                type: 'POST',
                url: Routing.generate('invoice_generate', { uploadId: uploadId }),
                contentType: false,
                processData: false,
                data: this.buildFormData()
            })
            .done((response) => this.eventEmitter.emit(EVENTS.UPLOAD_COMPLETED, response))
            .fail(this.handleFormError)
            .always(() => this.eventEmitter.emit(EVENTS.FORM_LOADED))
        ;
    };

    protected startUpload = () => {
        return new Promise((resolve, reject) => {
            $
                .ajax(Routing.generate('upload_start'))
                .done((response) => resolve(response.upload))
                .fail(() => reject('There was a problem starting the upload. Please try again.'));
        })
    }

    protected handleFormSuccess = (response:any) => {
        this.eventEmitter.emit(EVENTS.FORM_RESET);
        this.eventEmitter.emit(EVENTS.FORM_LOADED);
        if ('success' === response['status']) {
            this.eventEmitter.emit(EVENTS.COMMISSIONS_UPDATE);
        } else {
            this.handleFormError(response);
        }
    };

    protected handleFormError = (error:any) => {
        this.eventEmitter.emit(EVENTS.FORM_RESET);
        this.eventEmitter.emit(EVENTS.FORM_LOADED);
        if (error.responseText) {
            try {
                let response = JSON.parse(error.responseText);
                this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', response.message);
            } catch (e) {
                this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', 'There was a problem with the upload. Please try again and if it persists contact your system administrator.');
            }
        } else {
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', 'There was a problem with the upload. Please try again and if it persists contact your system administrator.');
        }
    };

    protected handleSendingStart = () => {
        this.$root.find(':input').prop('disabled', 'disabled');
    };

    protected handleSendingFinish = () => {
        this.$root.find(':input').removeAttr('disabled');
    };

}