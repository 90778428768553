import * as $ from 'jquery';
require('selectric/public/jquery.selectric.js');

export class SelectComponent {

    protected $root: JQuery;

    constructor(elem: HTMLElement)
    {
        this.$root = $(elem);
        (<any>this.$root).selectric({
            disableOnMobile: true,
            arrowButtonMarkup: '<b class="button icon-downarrow">&#x25be;</b>'
        });
    }
}