import {inject, injectable} from "inversify";
import {Component} from "../interface/component";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Routing} from "../modules/router";

@injectable()
export class InvoiceHistoryComponent implements Component {

    private $root:JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element: HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    protected bindUI = () => {
        this.eventEmitter.addListener(EVENTS.COMMISSIONS_UPDATE, this.handleReloadCommissions);
        this.eventEmitter.addListener(EVENTS.COMMISSIONS_RELOADED, this.handleCheckEmpty);
        this.handleReloadCommissions();
    };

    protected handleReloadCommissions = () => {
        this.$root.html('<div class="l-form__body card-section"><h3>Loading...</h3></div>');
        $
            .ajax({
                method: 'GET',
                url: Routing.generate('invoice_history'),
            })
            .done((response:any) => {
                this.$root.html(response);
                this.eventEmitter.emit(EVENTS.COMMISSIONS_RELOADED);
                this.$root.find('.js-invoice-history--archive').on({ click: this.handleArchiveItem });
                this.$root.find('.js-invoice-history--email').on({ click: this.handleEmailItem });
            })
            .fail(this.handleError)
        ;
    };

    protected handleCheckEmpty = () => {
        if (this.$root.find('li').length === 0) {
            this.handleEmpty();
        }
    };

    protected handleArchiveItem = (event:any) => {
        event.preventDefault();
        if (confirm('This will archive this invoice request. It will not delete generated invoices. Do you want to proceed?')) {
            let $target = $(event.currentTarget);
            let $item = $target.closest('.js-invoice-history--item');
            $item.hide();
            if ($item.length > 0 && $item.data('uuid')) {
                $
                    .ajax({
                        method: 'GET',
                        url: Routing.generate('invoice_request_archive', { id: $item.data('uuid') }),
                    })
                    .done(() => {
                        $item.remove();
                        this.eventEmitter.emit(EVENTS.COMMISSIONS_RELOADED);
                    })
                    .fail(() => {
                        $item.show();
                        alert('We could not archive this invoice request. Please try again.');
                    })
                ;
            }
        }
    };

    protected handleEmailItem = (event:any) => {
        event.preventDefault();
        if (confirm('This will send an email to the customers with their invoice CSV and PDF. Do you want to proceed?')) {
            let $target = $(event.currentTarget);
            $target.prop('disabled', 'disabled');
            $target.text('Sending...');
            let $item = $target.closest('.js-invoice-history--item');
            if ($item.length > 0 && $item.data('uuid')) {
                $
                    .ajax({
                        method: 'GET',
                        url: Routing.generate('invoice_request_email', { id: $item.data('uuid') }),
                    })
                    .done(() => {
                        this.eventEmitter.emit(EVENTS.COMMISSIONS_UPDATE);
                    })
                    .fail(() => {
                        alert('We could not send an email. Please try again.');
                    })
                    .always(() => {
                        $target.removeAttr('disabled');
                        $target.text('Email');
                    })
                ;
            }
        }
    };

    protected handleEmpty = () => {
        this.$root.html('<div class="l-form__body card-section"><p>There are currently no visible invoice requests. Use the invoice generator to generate invoices.</p></div>');
    }

    protected handleError = () => {
        this.$root.html('<div class="l-form__body card-section"><p>There was an error reloading the invoice requests.</p> <button class="button small secondary js-commission-history--retry">Retry</button></div>');
        this.$root.find('.js-commission-history--retry').on({ click: this.handleReloadCommissions });
    };
}