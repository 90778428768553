export class NavigationMobileComponent
{
    protected $root:JQuery;
    protected $overlayCanvas:JQuery;
    protected $contentCanvas:JQuery;
    protected $toggle:JQuery;
    protected $subnavigationToggle:JQuery;

    constructor(element:HTMLElement) {
        this.$root = $(element);
        this.$overlayCanvas = this.$root.closest('.js-mobile-nav__overlay');
        this.$contentCanvas = $('.l-page');
        this.$toggle = $('.js-mobile-nav__toggle');
        this.$subnavigationToggle = this.$root.find('.js-mobile-sub-nav');
        this.$toggle.each(this.bindToggleUI);
        this.$subnavigationToggle.each(this.dspSubGroup);
    }

    bindToggleUI = () => {
        if (!this.$toggle.data('initialised')) {
            this.$toggle.on({
                click: () => {
                    console.log('clicked', this.$contentCanvas);
                    if (this.$contentCanvas.hasClass('l-off-canvas--mobile-menu')) {
                        this.$toggle.removeClass('is-open');
                        this.$contentCanvas.removeClass('l-off-canvas--mobile-menu');
                        this.$overlayCanvas.removeClass('is-active');
                    } else {
                        this.$toggle.addClass('is-open');
                        this.$contentCanvas.addClass('l-off-canvas--mobile-menu');
                        this.$overlayCanvas.addClass('is-active');
                    }
                }
            });
            this.$toggle.data('initialised', true);
        }
    };

    dspSubGroup = () => {
        this.$subnavigationToggle.on({
            click: (e) => {
                let $target = $(e.currentTarget);
                $target.closest('.js-mobile-nav__list').find('.c-mobile-navigation__sub-wrap.open').removeClass('open');
                $target.next('div').toggleClass('open');
            }
        });
    };
}
