import {Routing} from "../modules/router";
import {inject, injectable} from "inversify";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Component} from "../interface/component";

@injectable()
export class FormComponent implements Component
{
    protected $root:JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element:HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    private bindUI() {
        if (this.$root.hasClass('js-form--ping')) {
            setInterval(() => {
                $.ajax({ url: Routing.generate('ping') });
            }, 30000);
        }
        this.$root.on(
            {
                submit: (event:any) => {
                    event.preventDefault();
                    let errors:string[] = [];
                    this.eventEmitter.emit(EVENTS.FORM_SUBMITTED, errors);
                    if (0 == errors.length) {
                        this.eventEmitter.emit(EVENTS.FORM_LOADING);
                        (<HTMLFormElement>this.$root.get(0)).submit();
                    } else {
                        this.eventEmitter.emit(EVENTS.FORM_ERROR);
                        console.log(errors);
                    }
                }
            }
        );
    }
}