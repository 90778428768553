import {inject, injectable} from "inversify";
import {Component} from "../interface/component";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Routing} from "../modules/router";

@injectable()
export class CommissionFormComponent implements Component {

    private $root:JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element: HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    protected bindUI = () => {
        this.$root.on('submit', this.handleSubmit);
        this.eventEmitter.addListener(EVENTS.FORM_SUBMITTED, this.handleSendingStart);
        this.eventEmitter.addListener(EVENTS.FORM_LOADED, this.handleSendingFinish);
    };

    protected handleSubmit = (event:any) => {
        event.preventDefault();
        $
            .ajax({
                method: 'POST',
                url: Routing.generate('commission_generate'),
                data: this.$root.serializeArray(),
                beforeSend: () => { this.eventEmitter.emit(EVENTS.FORM_SUBMITTED) }
            })
            .done(this.handleFormSuccess)
            .fail(this.handleFormError)
            .always(() => this.eventEmitter.emit(EVENTS.FORM_LOADED))
        ;
    };

    protected handleFormSuccess = (response:any) => {
        if ('success' === response['status']) {
            this.eventEmitter.emit(EVENTS.COMMISSIONS_UPDATE);
        } else {
            this.handleFormError(response);
        }
    };

    protected handleFormError = (error:any) => {
        this.eventEmitter.emit(EVENTS.FLASH_CLEAR);
        this.eventEmitter.emit(EVENTS.FORM_RESET);
        this.eventEmitter.emit(EVENTS.FORM_LOADED);
        if (error.responseText) {
            let response = JSON.parse(error.responseText);
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', response.message);
        } else if (error.message) {
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', error.message);
        } else {
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', 'There was a problem with the upload. Please try again and if it persists contact your system administrator.');
        }
    };

    protected handleSendingStart = () => {
        this.$root.find(':input').prop('disabled', 'disabled');
    };

    protected handleSendingFinish = () => {
        this.$root.find(':input').removeAttr('disabled');
    };

}