import {Routing} from "../modules/router";
import {inject, injectable} from "inversify";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Component} from "../interface/component";
import {Application} from "../application/application";

@injectable()
export class UploadButtonComponent implements Component
{
    protected label: string;
    protected $root:JQuery;
    protected $input:JQuery;

    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;
    @inject(SERVICE_IDENTIFIER.APPLICATION) public application: Application;

    init(element:HTMLElement) {
        this.$root = $(element);
        this.$input = $('#' + this.$root.prop('for'));
        this.bindUI();
    }

    protected bindUI() {
        this.$input.on({ change: this.handleChange });
        this.label = this.$root.html();
        this.eventEmitter.addListener(EVENTS.FORM_RESET, this.resetForm);
    }

    protected resetForm = () => {
        this.$root.html(this.label);
        this.$input.val(null);
    };

    protected handleChange = (ev:any) => {
        let value = ev.currentTarget.files[0];
        if (value) {
            this.$root.html('<i class="fas fa-check"></i> Ready to submit<br/><small><strong>' + value.name + "</strong></small>");
        }
    };
}