import {inject, injectable} from "inversify";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Component} from "../interface/component";

@injectable()
export class FlashComponent implements Component
{
    protected $root:JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element:HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    private bindUI() {
        this.eventEmitter.addListener(EVENTS.FLASH_MESSAGE, this.showMessage);
        this.eventEmitter.addListener(EVENTS.FLASH_CLEAR, this.clearMessages);
    }

    /**
     * Disable the button
     */
    showMessage = (type:string, message:string) => {
        type = 'error' === type ? 'error' : type;
        let flash = '<div class="callout ' + type + '">' + message + '</div>';
        this.$root.append(flash);
    };

    /**
     * Enable the button
     */
    clearMessages = () => {
        this.$root.html('');
    };
}