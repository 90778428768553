import {Routing} from "../modules/router";
import {inject, injectable} from "inversify";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Component} from "../interface/component";
import {ModalService} from "../service/modal";

@injectable()
export class UploadFormComponent implements Component
{
    protected $root:JQuery;
    protected usesCommand:boolean = false;

    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;
    @inject(SERVICE_IDENTIFIER.MODAL) public modalService: ModalService;

    init(element:HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    protected bindUI() {
        this.usesCommand = this.$root.data('use_command');
        this.eventEmitter.addListener(EVENTS.FORM_RESET, this.handleFormReset);
        this.eventEmitter.addListener(EVENTS.UPLOAD_COMPLETED, this.handleUploadComplete);
        this.eventEmitter.addListener(EVENTS.UPLOAD_FAILED, (error:any) => this.handleError(error));
        this.$root.on({ submit: this.handleSubmit });
    }

    protected handleFormReset = () => {
        this.$root.find(':input[type!="hidden"]').val(null);
    };

    protected handleSubmit = (event:any) => {
        event.preventDefault();
        let data:any = {};
        let $vendor = $(event.currentTarget).find('[data-vendor]');
        if ($vendor.length > 0) {
            data.vendor = $vendor.val();
        }
        this.eventEmitter.emit(EVENTS.FLASH_CLEAR);
        this.eventEmitter.emit(EVENTS.FORM_SUBMITTED);
        this.startUpload(data).then((uploadId) => this.handleUpload(uploadId))
    };

    protected handleUploadComplete = (response:any) => {
        if (undefined !== response.completed) {
            let redirect = this.$root.data('redirect_route');
            if (redirect) {
                this.modalService.showConfirm('Upload complete', '<p><strong>The upload has been completed successfully.</strong> Do you want to upload another file or have you finished?</p>', [
                    {
                        label: 'Upload again',
                        class: 'warning',
                        events: {
                            click: () => {
                                this.modalService.hideConfirm();
                                this.eventEmitter.emit(EVENTS.FORM_RESET);
                            }
                        }
                    },
                    {
                        label: 'I\'ve Finished',
                        class: 'success',
                        events: {
                            click: () => (<any>window).location = Routing.generate(redirect)
                        }
                    }
                ]);
            } else {
                let message = response.message ? response.message : 'The upload was completed successfully.';
                this.eventEmitter.emit(EVENTS.FORM_RESET);
                this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'success', message);
            }
        } else {
            let message = response.message ? response.message : 'The upload was not completed. It may be that there was a memory error. Please try again.';
            this.eventEmitter.emit(EVENTS.FORM_RESET);
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'error', message);
        }
    };

    protected handleError = (error:any) => {
        this.eventEmitter.emit(EVENTS.FLASH_CLEAR);
        this.eventEmitter.emit(EVENTS.FORM_RESET);
        this.eventEmitter.emit(EVENTS.FORM_LOADED);
        if (error.responseText) {
            let response = JSON.parse(error.responseText);
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', response.message);
        } else {
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', 'There was a problem with the upload. Please try again and if it persists contact your system administrator.');
        }
    };

    /**
     * Build the FormData object
     */
    protected buildFormData = () => {
        let formData = new FormData();
        this.$root.find(':input').each((key, element) => {
            let $element = $(element);
            let value = $element.is('[type="file"]') ? (<HTMLInputElement>element).files[0] : $element.val();
            formData.append($element.prop('name'), <string|Blob>value);
        });
        return formData
    };

    /**
     * Does the upload
     * @param uploadId
     */
    protected handleUpload = (uploadId:any) => {
        this.eventEmitter.emit(EVENTS.UPLOAD_STARTED, uploadId, this.usesCommand);
        $
            .ajax({
                type: 'POST',
                url: Routing.generate(this.$root.data('endpoint'), { uploadId: uploadId }),
                contentType: false,
                processData: false,
                data: this.buildFormData()
            })
            .done((response:any) => !this.usesCommand && this.eventEmitter.emit(EVENTS.UPLOAD_COMPLETED, response))
            .catch((error:any) => this.handleError(error))
            .fail((error:any) => this.handleError(error))
            .always(() => !this.usesCommand && this.eventEmitter.emit(EVENTS.UPLOAD_FINISHED))
    };

    protected startUpload = (data:any) => {
        return new Promise((resolve, reject) => {
            $
                .ajax({
                    url: Routing.generate('upload_start'),
                    data: data
                })
                .done((response) => resolve(response.upload))
                .fail(() => reject('There was a problem starting the upload. Please try again.'));
        })
    }
}