import "reflect-metadata";
import {Container, interfaces} from "inversify";
import SERVICE_IDENTIFIER from "./identifiers";
import {Application} from "../application/application";
import {Service} from "../interface/service";
import {ComponentService} from "../service/component";
import {Component} from "../interface/component";
import {TAG} from "./tags";
import {EventEmitter} from "../service/event-emitter";
import {FormComponent} from "../components/form-component";
import {ButtonComponent} from "../components/button-component";
import {SearchService} from "../service/search";
import {SelectService} from "../service/select";
import {PageComponent} from "../components/page-component";
import {CommissionFormComponent} from "../components/commission-form-component";
import {CommissionHistoryComponent} from "../components/commission-history-component";
import {UploadComponent} from "../components/upload-component";
import {TableComponent} from "../components/table-component";
import {FlashComponent} from "../components/flash-component";
import {LookupComponent} from "../components/lookup-component";
import {UploadButtonComponent} from "../components/upload-button-component";
import {UploadFormComponent} from "../components/upload-form-component";
import {UploadProgressComponent} from "../components/upload-progress-component";
import {SelectCustomerComponent} from "../components/select-customer-component";
import {InvoiceFormComponent} from "../components/invoice-form-component";
import {InvoiceHistoryComponent} from "../components/invoice-history-component";
import {InvoiceTableComponent} from "../components/invoice-table-component";
import {InvoiceUpdateComponent} from "../components/invoice-update-component";
import {ModalService} from "../service/modal";
import {LocationToolComponent} from "../components/location-tool-component";

let container = new Container();

container.bind<Application>(SERVICE_IDENTIFIER.APPLICATION).to(Application);
container.bind<EventEmitter>(SERVICE_IDENTIFIER.EVENTS).toConstantValue(new EventEmitter());

container.bind<ButtonComponent>(SERVICE_IDENTIFIER.COMPONENT).to(ButtonComponent).whenTargetNamed(TAG.BUTTON_COMPONENT);
container.bind<FormComponent>(SERVICE_IDENTIFIER.COMPONENT).to(FormComponent).whenTargetNamed(TAG.FORM_COMPONENT);
container.bind<PageComponent>(SERVICE_IDENTIFIER.COMPONENT).to(PageComponent).whenTargetNamed(TAG.PAGE_COMPONENT);
container.bind<CommissionFormComponent>(SERVICE_IDENTIFIER.COMPONENT).to(CommissionFormComponent).whenTargetNamed(TAG.COMMISSION_FORM);
container.bind<CommissionHistoryComponent>(SERVICE_IDENTIFIER.COMPONENT).to(CommissionHistoryComponent).whenTargetNamed(TAG.COMMISSION_HISTORY);

container.bind<InvoiceFormComponent>(SERVICE_IDENTIFIER.COMPONENT).to(InvoiceFormComponent).whenTargetNamed(TAG.INVOICE_FORM);
container.bind<InvoiceHistoryComponent>(SERVICE_IDENTIFIER.COMPONENT).to(InvoiceHistoryComponent).whenTargetNamed(TAG.INVOICE_HISTORY);
container.bind<InvoiceTableComponent>(SERVICE_IDENTIFIER.COMPONENT).to(InvoiceTableComponent).whenTargetNamed(TAG.INVOICE_TABLE);
container.bind<InvoiceUpdateComponent>(SERVICE_IDENTIFIER.COMPONENT).to(InvoiceUpdateComponent).whenTargetNamed(TAG.INVOICE_UPDATE);

container.bind<TableComponent>(SERVICE_IDENTIFIER.COMPONENT).to(TableComponent).whenTargetNamed(TAG.TABLE_COMPONENT);
container.bind<FlashComponent>(SERVICE_IDENTIFIER.COMPONENT).to(FlashComponent).whenTargetNamed(TAG.FLASH_COMPONENT);
container.bind<LookupComponent>(SERVICE_IDENTIFIER.COMPONENT).to(LookupComponent).whenTargetNamed(TAG.LOOKUP_COMPONENT);

container.bind<UploadComponent>(SERVICE_IDENTIFIER.COMPONENT).to(UploadComponent).whenTargetNamed(TAG.UPLOAD_COMPONENT);
container.bind<UploadProgressComponent>(SERVICE_IDENTIFIER.COMPONENT).to(UploadProgressComponent).whenTargetNamed(TAG.UPLOAD_PROGRESS);
container.bind<UploadButtonComponent>(SERVICE_IDENTIFIER.COMPONENT).to(UploadButtonComponent).whenTargetNamed(TAG.UPLOAD_BUTTON_COMPONENT);
container.bind<UploadFormComponent>(SERVICE_IDENTIFIER.COMPONENT).to(UploadFormComponent).whenTargetNamed(TAG.UPLOAD_FORM_COMPONENT);
container.bind<LocationToolComponent>(SERVICE_IDENTIFIER.COMPONENT).to(LocationToolComponent).whenTargetNamed(TAG.LOCATION_TOOL_COMPONENT);
container.bind<SelectCustomerComponent>(SERVICE_IDENTIFIER.COMPONENT).to(SelectCustomerComponent).whenTargetNamed(TAG.CUSTOMER_SELECT_COMPONENT);

container.bind<Service>(SERVICE_IDENTIFIER.COMPONENTS).toConstantValue(new ComponentService());
container.bind<Service>(SERVICE_IDENTIFIER.SEARCH).toConstantValue(new SearchService());
container.bind<Service>(SERVICE_IDENTIFIER.SELECT).toConstantValue(new SelectService());
container.bind<Service>(SERVICE_IDENTIFIER.MODAL).toConstantValue(new ModalService());

container.bind<interfaces.Factory<Component>>("Factory<Component>").toFactory<Component>((context) => {
    return (named: string) => (element: HTMLElement) => {
        let component = context.container.getNamed<Component>(SERVICE_IDENTIFIER.COMPONENT, named);
        component.init(element);
        return component;
    };
});
export default container;