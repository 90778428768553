import {Routing} from "../modules/router";
import {inject, injectable} from "inversify";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Component} from "../interface/component";
import Timeout = NodeJS.Timeout;

@injectable()
export class UploadProgressComponent implements Component
{
    protected originalLabel:string;
    protected uploading:any;
    protected $root:JQuery;
    protected $label:JQuery;
    protected $progress:JQuery;
    protected $output:JQuery;

    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element:HTMLElement) {
        this.$root = $(element);
        this.$label = this.$root.find('.js-progress--heading');
        this.originalLabel = this.$label.html();
        this.$progress = this.$root.find('.js-progress--meter');
        this.$output = this.$root.find('.js-progress--output');
        this.bindUI();
    }

    protected bindUI() {
        this.eventEmitter.addListener(EVENTS.FORM_RESET, this.handleFailed);
        this.eventEmitter.addListener(EVENTS.UPLOAD_STARTED, this.handleStarted);
        this.eventEmitter.addListener(EVENTS.UPLOAD_FAILED, this.handleFailed);
        this.eventEmitter.addListener(EVENTS.UPLOAD_COMPLETED, this.handleCompleted);
    }

    protected handleStarted = (uploadId:number, usesCommand:boolean) => {
        this.$root.foundation('open');
        this.uploading = setInterval(() => this.checkUpload(uploadId, usesCommand), 2000);
    };

    protected handleFailed = () => {
        clearInterval(this.uploading);
        this.$root.foundation('close');
        this.$label.html(this.originalLabel);
        this.$progress.css('width', "0%");
    };

    protected handleCompleted = () => {
        clearInterval(this.uploading);
        this.$root.foundation('close');
        this.$label.html(this.originalLabel);
        this.$progress.css('width', "0%");
    };

    protected checkUpload = (upload:any, usesCommand:boolean) => {
        $
            .ajax({
                type: 'GET',
                url: Routing.generate('upload_check', { id: upload }),
                success: (response:any) => {
                    this.$label.html(response.description);
                    if (null !== response.completed) {
                        clearInterval(this.uploading);
                        this.uploading = null;
                        if (usesCommand) {
                            this.eventEmitter.emit(EVENTS.UPLOAD_COMPLETED, response);
                            this.eventEmitter.emit(EVENTS.UPLOAD_FINISHED);
                        }
                        this.$progress.css('width', "100%");
                        this.$output.text("Complete");
                    } else if (null !== response.uploaded) {
                        let progress = Math.ceil(response.progress / response.total * 100);
                        this.$progress.css('width', progress + "%");
                        this.$output.text(progress + "%");
                    }
                }
            })
        ;
    };
}