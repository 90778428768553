import {injectable} from "inversify";
import {Service} from "../interface/service";

@injectable()
export class SelectService implements Service {

    selected:any = {};

    constructor() {
        let selected = sessionStorage.getItem("service.selected");
        this.selected = selected ? JSON.parse(selected) : {};
    }

    change = (namespace:string, key:string, selected:boolean) => {
        if (undefined === this.selected[namespace]) {
            this.selected[namespace] = {};
        }
        this.selected[namespace][key] = selected;
        sessionStorage.setItem("service.selected", JSON.stringify(this.selected));
    };

    list = (namespace:string) => {
        if (undefined === this.selected[namespace]) {
            this.selected[namespace] = {};
        }
        return this.selected[namespace];
    };

    getSelected = (namespace:string, array:boolean = false) => {
        let output:any = array ? [] : {};
        let list = this.list(namespace);
        Object.keys(list).forEach(key => {
            if (list[key]) {
                if (array) {
                    output.push(key);
                } else {
                    output[key] = true;
                }
            }
        });
        return output;
    };

    clear = (namespace:string) => {
        this.selected[namespace] = {};
        sessionStorage.setItem("service.selected", JSON.stringify(this.selected));
    };

    clearAll = () => {
        this.selected = {};
        sessionStorage.setItem("service.selected", JSON.stringify(this.selected));
    };
}