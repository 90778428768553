import {Routing} from "../modules/router";
import {inject, injectable} from "inversify";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Component} from "../interface/component";

@injectable()
export class UploadComponent implements Component
{
    protected $root:JQuery;
    protected $progress:JQuery;
    protected $label:JQuery;
    protected $input:JQuery;

    protected endpoint:string;
    protected uploading:any;
    protected labelDefaultContent:any;

    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element:HTMLElement) {
        this.$root = $(element);
        this.$label = this.$root.find('.js-upload--button');
        this.$input = this.$root.find('#' + this.$label.prop('for'));
        this.$progress = this.$root.find('.js-upload--progress');
        this.bindUI();
    }

    protected bindUI() {
        this.labelDefaultContent = this.$label.html();
        this.endpoint = this.$root.data('endpoint');
        this.eventEmitter.addListener(EVENTS.UPLOAD_FAILED, (error:any) => this.handleError(error));
        this.eventEmitter.addListener(EVENTS.FORM_LOADED, () => this.$progress.removeClass('c-upload-progress--active'));
        if (this.endpoint) {
            this.$input.on({ change: this.startUpload });
            if (this.$root.data('redirect_route')) {
                this.eventEmitter.addListener(EVENTS.UPLOAD_COMPLETED, () => this.handleUploadComplete);
            }
        }
    }

    protected handleUploadComplete = () => {
        let redirect = this.$root.data('redirect_route');
        (<any>window).location = Routing.generate(redirect);
    };

    protected handleError = (error:any) => {
        clearInterval(this.uploading);
        this.uploading = null;
        this.$input.val(null);
        this.eventEmitter.emit(EVENTS.UPLOAD_FAILED);
        if (error.responseText) {
            let response = JSON.parse(error.responseText);
            console.log(response);
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', response.message);
        } else {
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', 'There was a problem with the upload. Please try again and if it persists contact your system administrator.');
        }
    };

    protected checkUpload = (upload:any) => {
        $
            .ajax({
                type: 'GET',
                url: Routing.generate('upload_check', { id: upload }),
                success: (response:any) => {
                    if (null !== response.completed) {
                        clearInterval(this.uploading);
                        this.uploading = null;
                        this.$progress.find('.js-upload--progress-meter').css('width', "100%");
                        this.$progress.find('.js-upload--progress-output').text("Complete");
                    } else if (null !== response.uploaded) {
                        this.eventEmitter.emit(EVENTS.UPLOAD_PROGRSS);
                        let progress = Math.ceil(response.progress / response.total * 100);
                        this.$progress.find('.js-upload--progress-meter').css('width', progress + "%");
                        this.$progress.find('.js-upload--progress-output').text(progress + "%");
                    }
                }
            })
        ;
    };

    protected handleUpload = (target:any, upload:any) => {
        this.uploading = setInterval(() => this.checkUpload(upload), 1000);
        let formData = new FormData();
        this.$root.find(':input').each((key, element) => {
            let $element = $(element);
            let value = $element.is('[type="file"') ? (<HTMLInputElement>element).files[0] : $element.val();
            formData.append($element.prop('name'), <string|Blob>value);
        });
        $
            .ajax({
                type: 'POST',
                url: Routing.generate(this.endpoint, { uploadId: upload }),
                contentType: false,
                processData: false,
                data: formData
            })
            .done(() => {
                this.eventEmitter.emit(EVENTS.UPLOAD_COMPLETED);
            })
            .fail((error:any) => {
                this.handleError(error);
                this.eventEmitter.emit(EVENTS.FORM_LOADED);
            })
            .always(() => {
                this.$label.html(this.labelDefaultContent);
                this.eventEmitter.emit(EVENTS.UPLOAD_FINISHED);
            })
    };

    protected startUpload = (ev:any) => {
        console.log('this this happen?', this.uploading);
        if (!this.uploading) {
            ev.preventDefault();
            this.eventEmitter.emit(EVENTS.FLASH_CLEAR);
            this.eventEmitter.emit(EVENTS.FORM_SUBMITTED);
            this.$label.text('Uploading...');
            $
                .ajax({
                    type: 'GET',
                    url: Routing.generate('upload_start'),
                })
                .done((response) => {
                    this.handleUpload(ev.target, response.upload)
                })
                .fail(() => alert('There was a problem starting the upload. Please try again.'));
        }
    }
}