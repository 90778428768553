export class ModalComponent {

    private $button:JQuery;
    private $modal:JQuery;

    constructor(element:HTMLElement) {
        this.$modal = $(element);
        this.$button = $('[data-target="' + this.$modal.data('name') + '"]');
        if (this.$button.length > 0) {
            this.bindUI();
        }
    }

    protected bindUI = () => {
        this.$button.on({ click: this.showModal });
    };

    protected showModal = () => {
        this.$modal.foundation('open');
    };
}