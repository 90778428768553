import {injectable} from "inversify";
import {Service} from "../interface/service";

@injectable()
export class SearchService implements Service {

    $page:JQuery;

    constructor() {
        this.$page = $('.js-page');
    }

    loadParams(search:JQuery.NameValuePair[] = []) {
        let params:any = {};
        if (undefined !== this.$page.data('search')) {
            Object.keys(this.$page.data('search')).forEach(key => params[key] = this.$page.data('search')[key]);
        }
        search.forEach((item) => params[item.name] = item.value);
        if (undefined !== this.$page.data('filter')) {
            params.filter = this.$page.data('filter');
        }
        return params;
    }
}