import {inject, injectable} from "inversify";
import {Component} from "../interface/component";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Routing} from "../modules/router";

@injectable()
export class InvoiceTableComponent implements Component {

    private $root:JQuery;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element: HTMLElement) {
        this.$root = $(element);
        this.bindUI();
    }

    protected bindUI = () => {
        this.eventEmitter.addListener(EVENTS.INVOICE_UPDATED, this.handleItemUpdated);
        this.$root.find('.js-invoice-table__send').on({ click: this.handleEmailItem });
        this.$root.find('.js-invoice-table__update').on({ click: this.handleUpdateItem });
        this.$root.find('.js-invoice-table__mark-paid').on({ click: this.handleMarkPaid });
    };

    protected handleError = (error:any) => {
        if (error.message) {
            alert(error.message);
        } else {
            alert('There was a problem sending this invoice. Please try again and if it persists contact your system administrator.');
        }
    };

    protected handleUpdateItem = (event:any) => {
        let $target = $(event.currentTarget);
        let $item = $target.closest('tr');
        this.eventEmitter.emit(EVENTS.INVOICE_UPDATE, $item);
    };

    protected handleMarkPaid = (event:any) => {
        event.preventDefault();
        let $button = $(event.currentTarget);
        if (!$button.hasClass('disabled')) {
            let $item = $button.closest('[data-uuid]');
            this.handleItemPay($item);
            let uuid = $item.data('uuid');
            $
                .ajax({
                    type: 'POST',
                    url: Routing.generate('invoice_pay', { id: uuid }),
                    contentType: false,
                    processData: false
                })
                .done((response) => {
                    if ('success' === response.status) {
                        this.handleItemPaid($item);
                    } else {
                        this.handleItemPayFailed($item);
                        alert(response.message);
                    }
            })
            ;
        }
    };

    /**
     * Triggered by an event when the item is updated
     * @param item
     * @param status
     * @param statusOutput
     * @param sent
     */
    protected handleItemUpdated = (item:any, status:string, statusOutput:string, sent:string) =>
    {
        $(item).data('status', status);
        $(item).data('sent', !!sent);
        $(item)
            .find('.js-invoice-table__sent-date')
            .html(sent);
        $(item)
            .find('.js-invoice-table__status')
            .html(statusOutput);
        console.log(status);
        if ('paid' !== status) {
            console.log($(item).find('.js-invoice-table__mark-paid'));
            $(item)
                .find('.js-invoice-table__mark-paid')
                .removeClass('disabled');
        }
    };

    protected handleItemPay = (item:any, statusOutput:string = '<span class="label success">Paid</span>') =>
    {
        let $itemStatus = $(item).find('.js-invoice-table__status');
        $itemStatus.data('savedStatusOutput', $itemStatus.html());
        $itemStatus.html(statusOutput);
        $(item)
            .find('.js-invoice-table__mark-paid')
            .addClass('disabled');
    };

    protected handleItemPayFailed = (item:any) =>
    {
        let $itemStatus = $(item).find('.js-invoice-table__status');
        $itemStatus.html($itemStatus.data('savedStatusOutput'));
        $itemStatus.data('savedStatusOutput', null);
        $(item)
            .find('.js-invoice-table__mark-paid')
            .removeClass('disabled');
    };

    protected handleItemPaid = (item:any, status:string = 'paid', statusOutput:string = '<span class="label success">Paid</span>') =>
    {
        $(item).data('status', status);
        $(item)
            .find('.js-invoice-table__status')
            .html(statusOutput);
        $(item)
            .find('.js-invoice-table__mark-paid')
            .addClass('disabled')
        ;
    };

    protected handleEmailItem = (event:any) => {
        event.preventDefault();
        if (confirm('This will send an email to the customer with their invoice CSV and PDF. Do you want to proceed?')) {
            let $target = $(event.currentTarget);
            $target.prop('disabled', 'disabled');
            let $item = $target.closest('tr');
            if ($item.length > 0 && $item.data('uuid')) {
                $
                    .ajax({
                        method: 'GET',
                        url: Routing.generate('invoice_email', { id: $item.data('uuid') }),
                    })
                    .done((response) => {
                        if (response.sent) {
                            $item.data('sent', 1);
                            $item.data('status', 'sent');
                            $item
                                .find('.js-invoice-table__sent-date')
                                .html(response.sent)
                            ;
                        } else {
                            this.handleError(response)
                        }
                    })
                    .fail(this.handleError)
                    .always(() => $target.removeAttr('disabled'))
                ;
            }
        }
    };
}