export const TAG = {
    PAGE_COMPONENT: 'PAGE_COMPONENT',
    BUTTON_COMPONENT: 'BUTTON_COMPONENT',
    FORM_COMPONENT: 'FORM_COMPONENT',
    IMAGE_UPLOAD_COMPONENT: 'IMAGE_UPLOAD_COMPONENT',
    FILE_UPLOAD_COMPONENT: 'FILE_UPLOAD_COMPONENT',
    CONTENT_COMPONENT: 'CONTENT_COMPONENT',
    BLOCKS_COMPONENT: 'BLOCKS_COMPONENT',
    BLOCKS_CHOICES_COMPONENT: 'BLOCKS_CHOICES_COMPONENT',
    CKEDITOR_COMPONENT: 'CKEDITOR_COMPONENT',
    CALL_TO_ACTION_COMPONENT: 'CALL_TO_ACTION_COMPONENT',
    BODY_COMPONENT: 'BODY_COMPONENT',
    INLINE_PAGINATION: 'INLINE_PAGINATION',
    INLINE_SEARCH: 'INLINE_SEARCH',
    INLINE_FILTER: 'INLINE_FILTER',
    TABLE_COMPONENT: 'TABLE_COMPONENT',
    BULK_ACTION_COMPONENT: 'BULK_ACTION_COMPONENT',

    COMMISSION_FORM: 'COMMISSION_FORM',
    COMMISSION_HISTORY: 'COMMISSION_HISTORY',

    INVOICE_FORM: 'INVOICE_FORM',
    INVOICE_HISTORY: 'INVOICE_HISTORY',
    INVOICE_TABLE: 'INVOICE_TABLE',
    INVOICE_UPDATE: 'INVOICE_UPDATE',

    UPLOAD_COMPONENT: 'UPLOAD_COMPONENT',
    UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
    UPLOAD_FORM_COMPONENT: 'UPLOAD_FORM_COMPONENT',
    LOCATION_TOOL_COMPONENT: 'LOCATION_TOOL_COMPONENT',
    UPLOAD_BUTTON_COMPONENT: 'UPLOAD_BUTTON_COMPONENT',

    FLASH_COMPONENT: 'FLASH_COMPONENT',
    LOOKUP_COMPONENT: 'LOOKUP_COMPONENT',

    CUSTOMER_SELECT_COMPONENT: 'CUSTOMER_SELECT_COMPONENT',


    MODAL_SERVICE: 'MODAL_SERVICE',
};