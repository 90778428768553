import * as $ from 'jquery';
import "reflect-metadata"
import {inject, injectable} from "inversify";
import {Component} from "../interface/component";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {ComponentService} from "../service/component";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";

@injectable()
export class Application {

    @inject("Factory<Component>") public factory: (type: string) => (element: HTMLElement) => Component;
    @inject(SERVICE_IDENTIFIER.COMPONENTS) public components: ComponentService;
    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init() {
        this.initComponents();
        Application.initFunctions();
        this.eventEmitter.addListener(EVENTS.RELOAD_COMPONENTS, () => Application.initFunctions());
    }

    protected static getComponentType(component:any) {
        let type = '';
        if (typeof component.class == 'string') {
            type = component.class;
        } else {
            let object = Object.create(component.class.prototype);
            type = object.constructor.name;
        }
        return type;
    }

    /**
     * This sets up components linking an instance of the object to each selector defined in the component array above
     */
    public initComponents(container?: HTMLElement) {
        this.components.getComponents().forEach((component:any) => {
            $(component.elements, container).each((key, element) => {
                let initialised = $(element).data('initialised') ? $(element).data('initialised') : [];
                let type = Application.getComponentType(component);
                if (!initialised.find((initialisedType:any) => initialisedType == type)) {
                    if (typeof component.class == 'string') {
                        this.factory(component.class)(<HTMLElement>element);
                    } else {
                        let object = Object.create(component.class.prototype);
                        object.constructor.apply(object, [<HTMLElement>element]);
                    }
                    initialised.push(type);
                    $(element).data('initialised', initialised);
                }
            })
        });
    }


    public static initFunctions() {
        $('.no-js').removeClass('no-js').addClass('js');
    }
}