import {inject, injectable} from "inversify";
import {Component} from "../interface/component";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import {Routing} from "../modules/router";
import EVENTS from "../events/events";

@injectable()
export class LookupComponent implements Component {

    protected $root: JQuery;
    protected $modal: JQuery;
    protected $searcHForm: JQuery;
    protected $searchResults: JQuery;

    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;

    init(element: HTMLElement) {
        this.$root = $(element);
        console.log(this.$root);
        this.$modal = $('#lookup-modal');
        this.$searchResults = this.$modal.find('.search-results');
        this.$searcHForm = this.$modal.find('#lookup-form');
        this.bindUI();
    }

    private bindUI() {
        $('[data-action="venue-lookup"]').on('click', this.handleVenueLookup);
    };

    private handleVenueLookup = () => {
        this.clearErrors();
        this.$modal.find('.search-results').hide();
        this.$modal.foundation('open');
        this.$modal.find('form').on('submit', this.doVenueLookup);
    };

    private doVenueLookup = (event: any) => {
        event.preventDefault();
        this.clearErrors();
        let name = this.$searcHForm.find('#lookup_location_name').val();
        let region = this.$searcHForm.find('#lookup_location_region').val();
        if (name == '') {
            this.addError(this.$searcHForm.find('#lookup_location_name'), 'This value is required');
        }
        if (region == '') {
            this.addError(this.$searcHForm.find('#lookup_location_region'), 'This value is required');
        }
        if (name != '' && region != '') {
            this.$searcHForm.find('.alert').remove();
            this.disableSearchForm();
            $.ajax(Routing.generate('visits_lookup', {name: name, region: region})).done((response) => {
                if (response.length == 0) {
                    this.addFormError('No locations were found matching those keywords in that location.');
                }
                if (response.length == 1) {
                    this.populateForm(response[0]);
                }
                if (response.length > 1) {
                    this.showLookResults(response);
                }
                this.enableSearchForm();
            });
        }
    };

    private showLookResults = (results: any) => {
        results.forEach((venue: any) => {
            var $row = $('<tr><td class="name">' + venue.name + '<br/><small>' + venue.location.formattedAddress.join(', ') + '</small></td><td><button data-action="select-venue" class="button small default">Select</button></td></tr>');
            $row.data('venue', venue);
            this.$searchResults.find('table tbody').append($row);
        });
        this.$searcHForm.closest('.search-form').hide();
        this.$searchResults.show();
        this.$searchResults.find('[data-action="select-venue"]').on('click', (event) => {
            event.preventDefault();
            let $target = $(event.currentTarget);
            let venue = $target.closest('tr').data('venue');
            console.log( $target.closest('tr'), venue);
            this.populateForm(venue);
        });
        this.$searchResults.find('[data-action="reset-lookup"]').on('click', this.resetSearchForm);
    };

    private addFormError = (error: string) => {
        var html = '<div class="callout alert small">' + error + '</div>';
        this.$searcHForm.before(html)
    };

    private addError = (element:any, error:any) => {
        let $element = $(element);
        let $container = $element.closest('div');
        $container.find('.alert').remove();
        let html = '<div class="callout alert small">' + error + '</div>';
        $element.addClass('error');
        $element.after(html);
    };

    private clearErrors = () => {
        this.$searcHForm.find('.callout').remove();
    };

    resetSearchForm = () => {
        this.$searcHForm.closest('.reveal').find('.search-results').hide();
        this.$searcHForm.closest('.reveal').find('.search-results').find('table tbody').html('');
        this.$searcHForm.closest('.reveal').find('.search-form').show();
        this.$searcHForm.find('input').val('');
        this.$searcHForm.find('.alert').remove();
    };

    disableSearchForm = () => {
        this.eventEmitter.emit(EVENTS.FORM_SUBMITTED);
        this.$searcHForm.find('input').removeProp('disabled');
        this.$searcHForm.find('input[type="submit"]').addClass('disabled');
    };

    enableSearchForm = () => {
        this.eventEmitter.emit(EVENTS.FORM_LOADED);
        this.$searcHForm.find('input').removeProp('disabled');
        this.$searcHForm.find('input[type="submit"]').removeClass('disabled');
    };

    populateForm = (object: any) => {
        this.resetSearchForm();
        console.log(object);
        this.$modal.foundation('close');
        this.$root.find('#visit_practice_name').val(object.name);
        this.$root.find('#visit_practice_postalcode').val(object.location.postalCode);
    };
}