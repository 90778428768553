require("@babel/polyfill");

const SERVICE_IDENTIFIER = {
    COMPONENTS: Symbol.for('COMPONENTS'),
    SEARCH: Symbol.for('SEARCH'),
    SELECT: Symbol.for('SELECT'),
    MODAL: Symbol.for('MODAL'),
    APPLICATION: Symbol.for('APPLICATION'),
    COMPONENT: Symbol.for('Component'),
    SERVICE: Symbol.for('SERVICE'),
    ENTITY: Symbol.for('ENTITY'),
    TRANSITION: Symbol.for('TRANSITION'),
    EVENTS: Symbol.for('EVENTS'),
};

export default SERVICE_IDENTIFIER;