import {injectable} from "inversify";
import {Service} from "../interface/service";

@injectable()
export class ComponentService implements Service {
    components:any = [];
    addComponent(elements:any, component:any) {
        this.components.push({ elements: elements, class: component});
    }
    getComponents():any[] {
        return this.components;
    }
}