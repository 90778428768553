import {Routing} from "../modules/router";
import {inject, injectable} from "inversify";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";
import EVENTS from "../events/events";
import {Component} from "../interface/component";
import {ModalService} from "../service/modal";
import {UploadFormComponent} from "./upload-form-component";

@injectable()
export class LocationToolComponent extends UploadFormComponent implements Component
{
    protected $root:JQuery;

    @inject(SERVICE_IDENTIFIER.EVENTS) public eventEmitter: EventEmitter;
    @inject(SERVICE_IDENTIFIER.MODAL) public modalService: ModalService;

    protected handleSubmit = (event:any) => {
        event.preventDefault();
        let data:any = {};
        let $vendor = $(event.currentTarget).find('[data-vendor]');
        if ($vendor.length > 0) {
            data.vendor = $vendor.val();
        }
        data.extras = { 'geocode': true };
        this.eventEmitter.emit(EVENTS.FLASH_CLEAR);
        this.eventEmitter.emit(EVENTS.FORM_SUBMITTED);
        this.startUpload(data).then((uploadId) => this.handleUpload(uploadId))
    };

    protected handleUploadComplete = (response:any) => {
        if (undefined !== response.completed) {
            console.log(response);
            let redirect = this.$root.data('redirect_route');
            let $modal = this.modalService.showConfirm('Upload complete', '<p><strong>The upload has been completed successfully and a CSV file has been generated.</strong> Click on the link below to download the file.</p>', [
                {
                    label: 'Download import file',
                    class: 'warning',
                    events: {
                        click: () => {
                            $modal
                                .find('button')
                                .attr('disabled', 'disabled')
                                .addClass('disabled')
                            ;
                            $modal.find('button.warning').text('Downloading...');
                            (<any>window).location = Routing.generate('tools_locations_download', { uploadId: response.id })
                        }
                    }
                },
                {
                    label: 'I\'ve Finished',
                    class: 'success',
                    events: {
                        click: () => (<any>window).location = Routing.generate(redirect)
                    }
                }
            ]);

        } else {
            let message = response.message ? response.message : 'The upload was not completed. It may be that there was a memory error. Please try again.';
            this.eventEmitter.emit(EVENTS.FORM_RESET);
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'error', message);
        }
    };
}