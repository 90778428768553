import {Component} from "../interface/component";
import EVENTS from "../events/events";
import {inject, injectable} from "inversify";
import SERVICE_IDENTIFIER from "../container/identifiers";
import {EventEmitter} from "../service/event-emitter";

@injectable()
export abstract class AbstractFormComponent implements Component {

    protected $root:JQuery;
    public eventEmitter: EventEmitter;

    abstract init(element:HTMLElement): void;

    protected handleFormError = (error:any) => {
        this.eventEmitter.emit(EVENTS.FORM_RESET);
        this.eventEmitter.emit(EVENTS.FORM_LOADED);
        if (error.responseText) {
            try {
                let response = JSON.parse(error.responseText);
                this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', response.message);
            } catch (e) {
                this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', 'There was a problem with the upload. Please try again and if it persists contact your system administrator.');
            }
        } else {
            this.eventEmitter.emit(EVENTS.FLASH_MESSAGE, 'alert', 'There was a problem with the upload. Please try again and if it persists contact your system administrator.');
        }
    };

    /**
     * Build the FormData object
     */
    protected buildFormData = () => {
        let formData = new FormData();
        this.$root.find(':input').each((key:any, element:any) => {
            let $element = $(element);
            let value:any = $element.val();
            if ($element.is('[type="file"]')) {
                value = <HTMLInputElement>element.files[0];
            }
            if (!$element.is('[type="checkbox"]') || $(element).is(':checked')) {
                formData.append($element.prop('name'), <string | Blob>value);
            }
        });
        return formData
    };

}