import {injectable} from "inversify";
import {Service} from "../interface/service";

class ModalButton {
    label:string;
    class:string;
    events?:any;
}

@injectable()
export class ModalService implements Service {

    protected $modal:JQuery;
    protected modalOuter = '<div class="c-modal reveal" id="general-modal" data-reveal></div>';
    protected modalHeading = '<h3 class=c-modal__heading></h3>';
    protected modalBody = '<div class="c-modal__body"></div>';
    protected modalFooter = '<div class="c-modal__footer"></div>';
    protected modalClose = '<button class="c-modal__close close-button" data-close aria-label="Close reveal" type="button"><span aria-hidden="true">&times;</span></button>';

    protected buildButton = (button:ModalButton) => {
        let $button = $('<button></button>');
        $button.html(button.label);
        $button.attr('class', 'button ' + button.class);
        if (button.events) {
            $button.on(button.events);
        }
        return $button;
    };

    protected buildModal = (heading:string, body:string, buttons: ModalButton[] = []) => {
        let $modal = $(this.modalOuter);
        let $modalHeading = $(this.modalHeading).html(heading);
        let $modalBody = $(this.modalBody).html(body);
        let $modalFooter = $(this.modalFooter);
        let $modalClose = $(this.modalClose);
        buttons.forEach(button => $modalFooter.append(this.buildButton(button)))
        $modal.append($modalHeading);
        $modal.append($modalBody);
        $modal.append($modalFooter);
        $modal.append($modalClose);
        new Foundation.Reveal($modal);
        return $modal;
    };

    protected destroyModal = () => {
        this.$modal.foundation('close');
        this.$modal.remove();
    };

    public showConfirm(heading:string, body:string, buttons: ModalButton[] = []) {
        if (undefined !== this.$modal) {
            this.destroyModal();
        }
        this.$modal = this.buildModal(heading, body, buttons);
        this.$modal.foundation('open');
        return this.$modal;
    }

    public hideConfirm = () => {
        if (undefined !== this.$modal) {
            this.destroyModal();
        }
    };
}